import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import DisplayPosts from "../components/DisplayPosts"

const News = ({ data }) => {
  const blogPosts = data.allSanityBlogPost.edges

  const [postCount, setpostCount] = useState(3)

  let displayBlogPosts = blogPosts.slice(0, postCount)

  const increasePostCount = () => {
    return setpostCount(postCount + 5)
  }

  return (
    <Layout>
      <SEO title="News" />
      <Hero className="" englishTitle="News" syllabics="ᐅᐢᑭ  ᐊᒋᒧᐃᐧᐣ"></Hero>
      {/* Displays blog posts */}
      <DisplayPosts posts={blogPosts} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityBlogPost(
      filter: { inNewsFeed: { eq: true } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...DisplayPostsFragment
        }
      }
    }

    backgroundImage: allFile(
      filter: { relativePath: { eq: "indexBackground.jpeg" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              quality: 90
              width: 1920
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`

export default News
